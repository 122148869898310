.navbar {
    align-items: center;
    background-color: $gray-dark;
    box-shadow: $box-shadow;
    display: flex;
    height: $nav-height;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1;

    &__left {
        flex-basis: 33%;
    }
    &__logo {
        text-align: center;
        flex-basis: 33%;
    }
    &__logo svg {
        max-width: 250px;
    }

    &__user {
        font-size: $font-small;
        text-align: right;
        position: relative;

        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-basis: 33%;
        max-width: 100px;

        @include tablet {
            max-width: inherit;
        }

        &__lang_switch{
            margin-left: 15px;
            font-weight: 600;
            opacity: .5;
        }
        
        &__submenu {
            position: absolute;
            margin-top: 20px;
            right: 0;

            & ul {
                padding: 0;
                list-style: none;
                background-color: $gray-dark;
                border: 1px solid $border-color;
            }
            & li {
                cursor: pointer;
                padding: 6px 26px;
                border-bottom: 1px solid $border-color;
            }
            & li:last-child {
                border-bottom: none;
            }
    
        }

        &__email {
            display: none;

            @include tablet {
                display: block;
            }
        }

    }
    
}


