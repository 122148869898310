.pagination {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    list-style: none;

    &__item {
        cursor: pointer;
        margin: 5px;

        &:hover .pagination__link {
            @extend .pagination__link--active;
        }

    }

    &__link {
        display: flex;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        font-size: .76rem;
        color: $primary-color;
        border: 1px solid $primary-color;
        border-radius: 50%;
        transition: all .3s ease;
        pointer-events: none;

        &--active {
            background-color: $primary-color;
            color: $white;
        }

    }

}
