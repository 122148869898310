.orgchart-container  {
    border: none;
    background: none;
}

.orgchart {
    background: none;
    padding: 100px 0;
}

.orgchart .oc-node .oc-edge {
    pointer-events: none;
    opacity: 0;
}

.orgchart ul li .oc-node {
    box-shadow: 0 0 5px rgba(black, .5);
}

.orgchart ul li .oc-node.selected,
.orgchart ul li .oc-node:hover {
    background-color: color(primary-color-hover);
}

.orgchart ul li .oc-node .oc-heading {
    background-color: color(gray-dark);
}

.orgchart > ul > li > ul li .oc-node::before,
.orgchart ul li .oc-node:not(:only-child)::after,
.orgchart .oc-node .position {
    background-color: color(primary-color);
}

.orgchart > ul > li > ul li:first-child::before,
.orgchart > ul > li > ul li:last-child::before,
.orgchart > ul > li > ul li::before {
    border-top: 2px solid color(primary-color);
}


.oc-node:hover {
    background: none;
}

.orgchart ul li .oc-node .oc-heading,
.orgchart ul li .oc-node .oc-content {
    min-width: 200px;
}

.orgchart ul li .oc-node .oc-content {
    height: auto;
    border: none;
}
