.iziToast {
    font-family: 'Nunito Sans', sans-serif;

    &.iziToast-color-cactus {

        &--blue {
            background: #70C6D6;
            background: linear-gradient(90deg, #47C0D5 0%, #70C6D6, #47C0D5 100%);
            border-color: rgb(71,192,213);
        } 

        &--red {
            background: #CC3821;
            background: linear-gradient(90deg, #CC341E 0%, #CC3821 50%, #CC341E 100%);
            border-color: rgb(192,52,30);
        } 
        &--yellow {
            background: #D5BB4B;
            background: linear-gradient(90deg, #D3A544 0%, #D5BB4B 50%, #D3A544 100%);
            border-color: rgb(211,165,68);
        } 

        &--green {
            background: #4FC65B;
            background: linear-gradient(90deg, #49B656 0%, #4FC65B 50%, #49B656 100%);
            border-color: rgb(73,182,86);
        } 
        
    }
}
