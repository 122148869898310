.list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &__name {
        flex-basis: 40%;
        font-size: 16px;
        font-weight: 500;
    }
    &__options {
        flex-basis: 10%;
        text-align: right;
    }
}