.btn {
    border-radius: 100px;
    font-size: .8rem;
    padding: .375em .75em;


    &:disabled{
        cursor: not-allowed;
    }


    &-cactus {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $text-button-color; 
        

        &:hover {
            background-color: $primary-color-hover;
            border-color: $primary-color-hover; 
            color: $text-button-color;
        }

        &--active {
            background-color: $primary-color-hover;
        }
        
        &-lg {
            @extend .btn-cactus;
            padding: .75em 2.6em .6em;
        }

        &--loading{
            background: linear-gradient(269deg,$primary-color-hover, darken($primary-color-hover, 10%));
            border-color: $border-color !important;
            background-size: 600% 600%;

            -webkit-animation: buttonLoading 1s ease infinite;
            -moz-animation: buttonLoading 1s ease infinite;
            -o-animation: buttonLoading 1s ease infinite;
            animation: buttonLoading 1s ease infinite;
        }

        &--red {
            background-color: $secondary-color;
            border-color: $secondary-color;
            color: $text-button-color; 

            &:hover {
                background-color: $secondary-color-hover;
                border-color: $secondary-color-hover; 
                color: $text-button-color;
            }

            &:disabled{
                background-color: $secondary-color-hover;
                border-color: $secondary-color-hover; 
                color: $text-button-color; 
            }
            &--loading{
                background: linear-gradient(269deg,$secondary-color-hover, darken($secondary-color-hover, 10%));
                border-color: $border-color !important;
                background-size: 600% 600%;

                -webkit-animation: buttonLoading 1s ease infinite;
                -moz-animation: buttonLoading 1s ease infinite;
                -o-animation: buttonLoading 1s ease infinite;
                animation: buttonLoading 1s ease infinite;
            }
        }

        &--info {
            @extend .btn-cactus--red;
            background-color: $info-color;
            border-color: $info-color;

            &:hover {
                background-color: $info-color;
                border-color: $info-color;
                color: $text-button-color;
            }

        }

    }
}
