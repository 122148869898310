.config {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    
    &__key {
        flex-basis: 60%;
        text-align: left;
        letter-spacing: .03em;
        font-size: 13px;
    }
    &__value {
        flex-basis: 30%;
        text-align: left;
        letter-spacing: .03em;
        font-size: 13px;
    }
    &__badge {
        flex-basis: 10%;
        text-align: right;
    }
}