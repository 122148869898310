$borderWidth: 10px;
$backgroundColor: red;

.eink-screen {
   position: relative;
   width: 100%;
   max-width: 300px;
   aspect-ratio: 16/9;
   // border: $borderWidth solid white;
   margin: 0 auto;
   overflow: hidden;

   &--vertical {
      @extend .eink-screen;
      aspect-ratio: 0.5;
      max-height: 300px;
   }

   // &__plug {
   //    position: absolute;
   //    content: '';
   //    top: 50%;
   //    left: -$borderWidth * 2;
   //    width: $borderWidth;
   //    height: $borderWidth;
   //    transform: translateY(-50%);
   //    background: $backgroundColor;

   //    &--vertical {
   //       position: absolute;
   //       content: '';
   //       top: -$borderWidth * 2;
   //       left: 50%;
   //       width: 10px;
   //       height: 10px;
   //       transform: translateX(-50%);
   //       background: $backgroundColor;
   //    }
   // }

   &__img {
      object-fit: contain;
      max-width: 100%;
      aspect-ratio: 16/9;
   }

}
