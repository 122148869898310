.form-control--dark {
    outline: initial;
    box-shadow: none;
    font-size: .8rem;
    padding: .4rem .75rem .5rem;
    line-height: 1.5;
    border: 1px solid $border-color;
    background-color: $gray-dark  !important;
    color: $gray-light;

    &:disabled {
        @extend .form-control--dark;
    }
}

.form {

    &__label {
        display: block;
        font-size: 14px;
        font-weight: bold;
    }

    &__invalid-feedback {
        color: $invalid-feedback;
        font-size: 12px;
    }

}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    @extend .form-control--dark;
    -webkit-text-fill-color: $gray-light;
    -webkit-box-shadow: 0 0 0px 1000px $gray-dark inset;
    border: 1px solid $border-color;
}

input{
    &:disabled {
            color: darken($gray-light, 30);
            cursor: no-drop;
            border: 1px solid transparent;
            padding-left: 0px;
            &::selection {
                background: none;
            }
    }
    &::placeholder {
        font-style: italic;
    }
}