#content {    
    padding-top: calc(#{$nav-height} + 20px);
    margin-left: $main-menu-small + $margin-panel;
    margin-right: $margin-panel;
    height: calc(100vh - #{$nav-height});

    @include tablet {
        margin-left: $main-menu + $margin-panel;
    }

    &.withRightColumn {
        margin-right: 0px;
    }
}
#right-panel {
    position: fixed;
    top: $nav-height;
    right: 0;
    bottom: 0;
    width: $right-panel;
    overflow-y: auto;
    z-index: 0;
}
#center-panel {
    width: calc(100vw - #{ $main-menu + $right-panel + $margin-panel + $margin-panel });
}