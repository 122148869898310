@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';

$grid-breakpoints: (
    xs: 0,
    sm: 600px,
    md: 800px,
    lg: 1000px,
    xl: 1280px,
);

$container-max-widths: (
    sm: 600px,
    md: 800px,
    lg: 1000px,
    xl: 1220px
);

@import '~bootstrap/scss/bootstrap';
@import "~nprogress/nprogress.css";
@import "~react-dropzone-uploader/dist/styles.css";

@import './fonts';
@import './medias';
@import './components/colors';
@import './org-chart';
@import './components/generics';
@import './components/iziToast';
@import './components/nprogress';
@import './components/animations';
@import './components/fontsizing';
@import './components/bootstrap/overwrite';
@import './components/panels';
@import './components/item';
@import './components/navheader';
@import './components/asidemenu';
@import './components/error';
@import './components/buttons';
@import './components/forms';
@import './components/auth';
@import './components/config';
@import './components/screen';
@import './components/playlist';
@import './components/pagination';
@import './components/cactus_modal';
@import './components/dropzone';
@import './components/map';
@import './components/schedule';
@import './components/eink';
