$dropzoneItemMargin: 5px;
$dropzoneInitialHeight: 200px;

@function getDropzoneItemWidth($columns, $margin) {
    $dropzoneItemSize: calc(100% / #{$columns});
    @return calc(#{$dropzoneItemSize} - (#{$margin} * 2) );
}

.dzu-dropzoneActive {
    border-color: $primary-color;
}

.dzu-inputLabel {
    color: $primary-color;
    font-family: inherit;
}

.dropzone:hover .dzu-dropzone {
    border-color: $primary-color !important;
}

.dzu-dropzone {
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    border-radius: 0;

    &--active {
        border-color: $primary-color !important;
    }
}

.dropzone-item {
    position: relative;
    border-bottom: transparent;
    width: getDropzoneItemWidth(1, $dropzoneItemMargin);
    height: $dropzoneInitialHeight;
    margin: $dropzoneItemMargin;
    background-color: #1b1b1b;

    @include tablet {
        width: getDropzoneItemWidth(2, $dropzoneItemMargin);
    }
    
    @include laptop {
        width: getDropzoneItemWidth(3, $dropzoneItemMargin);
    }
    
    @include desktop {
        width: getDropzoneItemWidth(4, $dropzoneItemMargin);
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        
        video {
            object-fit: cover;
        }

    }

    &__progress {
        position: absolute;
        z-index: 1;
        display: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba($primary-color, .3);
        opacity: 0;
        transition: all .3s ease;

        &--active {
            opacity: 1;
            display: flex;
        }

    }

    &__error {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $primary-color;
        color: white;
        text-align: center;
        padding: 10px;
    }

}

.dzu-inputLabelWithFiles {
    color: rgba($primary-color, .3);
    margin: 5px;
    background-color: transparent;
    border: 1px dashed rgba($primary-color, .3);
    width: getDropzoneItemWidth(1, $dropzoneItemMargin);
    height: $dropzoneInitialHeight;
    font-size: 2rem;
    font-weight: lighter;
    animation: dropzoneplus 1s ease-in;

    @include tablet {
        width: getDropzoneItemWidth(2, $dropzoneItemMargin);
    }
    
    @include laptop {
        width: getDropzoneItemWidth(3, $dropzoneItemMargin);
    }
    
    @include desktop {
        width: getDropzoneItemWidth(4, $dropzoneItemMargin);
    }

}

@keyframes dropzoneplus {
    from {opacity: 0;}
    to {opacity: 1;}
}
