.box-error {
    align-items: center;
    box-shadow: $box-shadow;
    display: flex;
    flex-direction: column;
    height: 30vh;
    justify-content: center;
    width: 50vw;
}
.box-error__error-code {
    font-size: 80px;
    font-weight: 700;
    line-height: 80px;
}
.box-error__error-text {
    font-size: 18px;
}