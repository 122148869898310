$filterHeight: 50px;

.schedule {
    min-height: 100%;
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr;  
	grid-template-rows: $filterHeight
                        30vh
                        1fr;
                        
    grid-template-areas: "filter"
                         "screens"
	                     "content";

    @include tablet {
        grid-template-columns: 200px 1fr;  
	    grid-template-rows: $filterHeight 1fr;
        grid-template-areas: "filter filter"
                             "screens content";
    }

    &__filter {
        grid-area: filter;
    }

    &__screens {
        grid-area: screens;
        overflow: auto;
    }

    &__content {
        grid-area: content;
    }

    &__toolbar {
        display: flex;
        justify-content: flex-end;
    }

}

.rbc-calendar{
    background-color: #232323;
    height: 630px;
}

.rbc-event {
    background-color: #FFA55C !important;
    border: none !important;
    color: #232223 !important;
    font-weight: bolder;
    text-align: center;
    //border-radius: 10px;
}

.rbc-screengroup-event {
    background-color: #ed8027 !important;
    border: none !important;
    color: #232223 !important;
    font-weight: bolder;
    text-align: center;
    //border-radius: 10px;
}

.rbc-allday-cell {
    display: none !important;
}

.rbc-time-slot {
    border: none !important;
}

.rbc-header{
    padding: 10px;
}

.rbc-header.rbc-today {
    color: #c0702f;
}

.rbc-today {
    background-color: transparent;
}

.rbc-time-header {
    background-color: #1b191b;
    border-top: 1px solid #393939;
}

.rbc-timeslot-group, .rbc-header, .rbc-time-header-content, .rbc-time-content > * + * > *, .rbc-time-content {
    border-color: #393939 !important;
}

.rbc-time-view {
    border: none;
}

.rbc-current-time-indicator {
    height: 2px;
    background-color: #c0702f;
    z-index: 0;
}

.rbc-events-container {
    margin-left: 8px;
    margin-right: 0 !important;
}

.rbc-toolbar {
    margin-top: 10px;
    padding: 0 20px;
}

.rbc-toolbar-label {
    font-weight: bold;
}

.rbc-event-label {
    display: none;
}

.rbc-event-content {
    display: flex;
    justify-content: center;
}

.event {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.event-title {
    font-size: 20px;
    font-weight: bold;
}

.event-start-end {
    font-size: 10px;
}

.schedule__toolbar{
    padding: 0 20px
}

.schedule-nav{
    border-bottom: 1px solid color(primary-color);
}

.schedule-nav.active {
    background-color: color(primary-color) !important;
    color: color(gray-dark) !important;
}

.schedule_priority{
    accent-color: color(primary-color);
    width: 100%;
}

.DayPickerInput input{
    background-color: color(black-light);
    color: color(gray-light);
    text-align: center;
    border-color: color(primary-color);
    border-width: 0 0 1px 0;
}

.DayPicker{
    background-color: color(black-light);
}

.DayPicker-Day{
    border-radius: 25% !important;
}

.DayPicker-Day:hover{
    background-color: #FFA55C !important;
    color: color(gray-dark) !important;
}

.DayPicker-Day--today{
    color: color(primary-color);
}

.DayPicker-Day--selected{
    background-color: color(primary-color) !important;
}

.DayPicker-Footer{
    display: flex;
    justify-content: center;
}

.DayPicker-TodayButton{
    background-color: color(primary-color) !important;
    color: color(text-button-color) !important;
    border-color: color(primary-color) !important;
    font-weight: bold;
}

.date-picker{
    color-scheme: dark;
    text-align: center;
}