aside.main-menu {
    background-color: $gray-dark;
    box-shadow: $box-shadow;
    height: calc(100vh - #{$nav-height});
    overflow-y: auto;
    position: fixed;
    top: $nav-height;
    width: $main-menu-small;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: all .3s ease-out;

    @include tablet {
        width: $main-menu;
    }

}
.main-menu ul {
    padding-left: 0;
    list-style: none;
}
.main-menu li a {
    position: relative;
    text-decoration: none;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    border-bottom: 1px solid #313131;
    color: $gray-light;
    cursor: pointer;
    text-align: center;

    -webkit-transition: color .3s;
    -webkit-transition: background .3s;
    transition: color .3s;
    transition: background .3s;

    span {
        
        display: none;

        @include tablet {
            display: block;
        }

    }

}


.main-menu ul li a.active {
    color: $primary-color;
}
.main-menu li a:hover {
    color: $primary-color;
    background: $black-light;
}
.main-menu ul li a.active:after {
    content: " ";
    background: $primary-color;
    color: $gray-dark;
    border-radius: 10px;
    position: absolute;
    width: 3px;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}
.main-menu ul li i {

    font-size: 20px;

    @include tablet {
        font-size: 32px;
        line-height: 42px;
    }

}
.main-menu__appVersion {
    font-size: 10px;
    text-align: center;
}