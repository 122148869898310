.playlist-item {
    @extend .list-item;

    &__name {
        @extend .list-item__name;

        .body {

        }

    }
    &__options {
        @extend .list-item__options;
    }

    &__content {

        @include desktop {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

    }
}


.playlist-media-list {

    & ul {
        padding: 0;
        list-style: none;
    }
   

    &__item {
        display: inline-flex;
        align-items: center;
        height: 50px;
        margin-bottom: 7px;
        border-bottom: 1px solid $border-color;
        padding-bottom: 7px;
        width: 100%;

        & img, video {
            width: 65px;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
        }

        &__info {
            margin: 0;
            width: 188px;
            margin-left: 15px;

            &__name {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: .9rem;
            }
            &__type {
                font-size: .8rem;
                color: $gray-dark-secondary;
                font-style: italic;
            }
        }
    }
}

.playlist-media-drop-area {
    height: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed;
    border-color: $border-color;
    -webkit-transition: border-color .1s linear;
    transition: border-color .1s linear;
}
.playlist-media-drop-area:hover {
    border-color: $primary-color-hover;
}


#playlist-medias {
    position: relative;
}

.drop-here-area {
    position: absolute;
    top: 0;
    background: rgba(0,0,0,.5);
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 9rem;

    &__border {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border: 1px dashed $primary-color-hover;
        border-radius: 5px;
    }

}

.playlist-detail-media-item {

    display: flex;
    align-items: center;
    justify-content: space-between;
    
}