.auth {

    &__box {
        width: 80vw;
        min-height: 30vh;
        padding: 60px;
        box-shadow: $box-shadow;

        @include tablet {
            max-width: 800px;
        }

        &--large {
            @include tablet {
                max-width: 1000px;
            }
        }

    }

    &__logo {
        max-width: 170px;
        display: block;
        margin: 0 auto;
    }

    &__form {
        margin-top: 30px;

        @include tablet {
            margin-top: inherit;
        }

    }

}
