$black-light            : #1b191b;
$gray-dark              : #232223;
$gray-dark-secondary    : #656465;
$gray-light             : #969696;
$white                  : #d0d0d0;
$primary-color          : #c0702f;
$primary-color-hover    : #9f5d27;
$secondary-color        : #aa4f43;
$secondary-color-hover  : #8d4137;
$success-color          : #3e884f;
$text-button-color      : #d0d0d0; 
$border-color           : #373637; // #96969626;
$invalid-feedback       : #8d4137;
$hr-color               : #0a0a0a;
$modal-box              : #1a1a1a;
$info-color             : #0280d3;

$colors : (
    black-light           : $black-light,
    gray-dark             : $gray-dark,
    gray-dark-secondary   : $gray-dark-secondary,
    gray-light            : $gray-light,
    white                 : $white,
    primary-color         : $primary-color,
    primary-color-hover   : $primary-color-hover,
    secondary-color       : $secondary-color,
    secondary-color-hover : $secondary-color-hover,
    success-color         : $success-color,
    text-button-color     : $text-button-color,
    border-color          : $border-color,
    invalid-feedback      : $invalid-feedback,
    hr-color              : $hr-color,
    modal-box             : $modal-box,
    info-color            : $info-color,
);

@function color($col) {
  @return map-get($colors, '#{$col}');
}

@each $key, $value in $colors {
  .color-#{$key} {
    color : $value !important;
  }
  .bg-#{$key} {
    background-color : $value !important;
  }
  .fill-#{$key} {
    fill : $value !important;
  }
  .stroke-#{$key} {
    stroke : $value !important;
  }
}